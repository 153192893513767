import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { Link } from "react-router-dom";
import { TablePagination, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { baseURL } from "../../../util/Config";
import noImage from "../../../assets/images/noImage.png";
import { getComplain, solvedComplain } from "../../../store/complain/action";
import { OPEN_COMPLAIN_DIALOG } from "../../../store/complain/types";
import ComplainDetails from "../../dialog/ComplainDetails";


const TablePaginationActions = React.lazy(() => import("../TablePagination"));

const ComplainRequest = (props) => {
  const dispatch = useDispatch();


  const [type, setType] = useState(() => {
    return localStorage.getItem('complainRequestTab') || "Pending"
  });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleTabChange = (newType) => {
    setType(newType);
    localStorage.setItem('complainRequestTab', newType);
  };

  useEffect(() => {
    dispatch(getComplain(type.toLowerCase()));
  }, [dispatch, type]);

  const complain = useSelector((state) => state.complain.complain);

  useEffect(() => {
    setData(complain);
  }, [complain]);

  // ... rest of the handlers (handleChangePage, handleChangeRowsPerPage, handleSearch, etc.)

  const handleSolvedComplain = (id) => {

    props.solvedComplain(id);
  };

  const handleViewComplainDetail = (data) => {
    dispatch({ type: OPEN_COMPLAIN_DIALOG, payload: data });
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Complain Request</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Complain Request</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="my-2">
            <button
              type="button"
              className={`btn btn-sm ${type === "Pending" ? "btn-info" : "disabledBtn"}`}
              onClick={() => handleTabChange("Pending")}
            >
              <span className="">Pending</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm ${type === "Solved" ? "btn-danger" : "disabledBtn"} ms-3`}
              onClick={() => handleTabChange("Solved")}
            >
              <span className="">Solved</span>
            </button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div className="card">
        {/* ... search bar section ... */}
        <div className="card-body card-overflow">
          <table className="table table-striped">
            <thead className="text-center">
              <tr>
                <th>No.</th>
                <th>User</th>
                <th>Complain Image</th>
                <th>Contact</th>
                <th>{type === "Pending" ? "CreatedAt" : "Solved date"}</th>
                {type === "Pending" && <th>Solved</th>}
                <th>Details</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {/* ... table rows mapping ... */}
            </tbody>
          </table>
          {/* ... pagination ... */}
        </div>
      </div>
      <ComplainDetails />
    </>
  );
};

export default connect(null, { getComplain, solvedComplain })(ComplainRequest); 